import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import ImageCarousel from '../components/ImageCarousel';

import pic1 from '../assets/images/bearing_body.jpg';
import pic2 from '../assets/images/die_block.jpg';
import pic3 from '../assets/images/electric_fitting.jpg';
import pic4 from '../assets/images/packing_finger.jpg';
import pic5 from '../assets/images/probe_housing.jpg';
import pic6 from '../assets/images/pulley_spacer.jpg';
import pic7 from '../assets/images/pump_block.jpg';
import pic8 from '../assets/images/spacer.jpg';
import pic9 from '../assets/images/standoff.jpg';
import pic10 from '../assets/images/thermostat.jpg';
import pic11 from '../assets/images/thermowells.jpg';
import pic12 from '../assets/images/thermowells2.jpg';

const images = [
  pic1,
  pic2,
  pic3,
  pic4,
  pic5,
  pic6,
  pic7,
  pic8,
  pic9,
  pic10,
  pic11,
  pic12,
];

const SampleProjectsPage = props => (
  <Layout>
    <Helmet>
      <title>JIT Machine Corp</title>
      <meta name="description" content="Landing Page" />
    </Helmet>
    <section id="banner" className="style2">
      <div className="inner">
        <header className="major">
          <h1>Sample Projects</h1>
        </header>
        <div className="content">
          <p>
            Just In Time Corporation provides CNC services to a variety of
            industries. Here are a few sample projects of precision machined
            parts. If you need something similar, let's talk.
          </p>
        </div>
      </div>
    </section>
    <ImageCarousel id="imageCarousel" images={images} />
  </Layout>
);

export default SampleProjectsPage;
