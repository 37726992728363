import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

import samplepic1 from '../../assets/images/pic08.jpg';
import samplepic2 from '../../assets/images/pic09.jpg';
import samplepic3 from '../../assets/images/pic10.jpg';

class ImageCarousel extends React.Component {
  constructor(props) {
    super(props);

    const windowWidth =
      typeof window != 'undefined' && window.innerWidth
        ? window.innerWidth
        : 750;

    this.state = {
      carouselWidth: windowWidth < 750 ? windowWidth : 750,
    };

    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions() {
    this.setState({
      carouselWidth: window.innerWidth < 750 ? window.innerWidth : 750,
    });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    let images = [samplepic1, samplepic2, samplepic3];

    if (this.props.images) {
      images = this.props.images;
    }

    const imagesHtml = images.map((image, i) => (
      <div key={image}>
        <img src={image} alt={`${i}`} />
      </div>
    ));

    return (
      <div id="imageCarousel">
        <Carousel
          centerMode
          showIndicators={false}
          centerSlidePercentage={50}
          emulateTouch
          infiniteLoop
          autoPlay
          interval={3500}
          width={`${this.state.carouselWidth}px`}
        >
          {imagesHtml}
        </Carousel>
      </div>
    );
  }
}

export default ImageCarousel;
